import { Button, type ButtonProps } from '#app/components/ui/button.tsx'
import { Link } from '@remix-run/react'

export default function ButtonLink({
	children,
	to,
	...props
}: ButtonProps & { to: string }) {
	return (
		<Link to={to}>
			<Button {...props}>{children}</Button>
		</Link>
	)
}
